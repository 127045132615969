<script>
import formSelectWrap from '../common-selector/form-select-wrap'
import cellList from './cell'
import emptyLoading from '../empty-loading/empty-loading'
import { i18n } from '@/main'
export default {
  components: {
    formSelectWrap,
    cellList,
    emptyLoading
  },
  props: {
    showPicker: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: i18n.t('请选择')
    },
    columns: {
      type: Array,
      default: function() {
        return []
      }
    },
    loading: {
      type: Boolean
    },
    empty: {
      type: Boolean
    },
    initData: {
      type: Array,
      default: function() {
        return []
      }
    },
    showThreeLevel: {
      type: Boolean
    },
    selectMaxLevel: {
      type: [String, Number]
    },
    brandDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeList: [], // 已选择的数据列表
      active: '', // 当前高亮的已选择的数据
      activeLevel: 0, // 当前活跃数据的所处
      cellActive: {} // 向cell传递点击的数据
    }
  },
  computed: {
    list: function() {
      if (this.activeLevel === 0) {
        return this.columns
      } else {
        if (this.selectMaxLevel && this.selectMaxLevel === this.activeList.length) return
        if (this.active?.children) return this.active.children
        if (this.activeLevel === 2) {
          return this.columns
        } else {
          const parentLevel = this.activeLevel - 1
          return this.activeList[parentLevel].children
        }
      }
    },
    showActiveList() {
      return this.activeList
    }
  },
  watch:{
    columns:{
      handler(value){
        const [ seriesCode, modelCode ] = this.initData
        if (seriesCode) {
          const data = value.find((item)=>item.seriesCode===seriesCode)
          if (!data) return
          this.active = data
          this.activeLevel = 0
          this.activeList = [this.active]
          if (modelCode) {
            const data = this.active.children.find((item)=>item.modelCode===modelCode)
            if (!data) return
            this.active = data
            this.activeLevel = 1
            this.activeList.push(this.active)
          }
          this.cellActive = this.active
        }
      }
    }
  },
  methods: {
    onChange(item) {
      this.active = item
    },
    open() {
      this.$refs.wrap.open()
    },
    // 操作区点击
    optClick(item, index) {
      const optLength = this.activeList.length
      if (index === optLength && index !== 0) return false
      if (this.brandDisabled && index === 0) return false
      this.cellActive = item
      this.active = this.activeList[index - 1]
      let num = optLength - index
      this.activeLevel = index
      this.activeList.splice(-num, num)
    },
    // 响应cell组件
    onCellChange(data) {
      this.$emit('cellChange', data)
      const { level, active } = data
      this.activeList[level] = active
      this.activeList = [...this.activeList]
      this.active = active
      // 不存在子项则为末端叶子节点
      if (!this.active.children) return
      this.activeLevel = level + 1
    },
    // 点击返回按钮
    onClickArrow() {
      if (this.activeList.length) {
        // 返回上一级
        const index = this.activeList.length -1
        this.optClick(this.activeList[index], index)
      } else {
        this.$emit('cancel')
      }
    }
  }
}
</script>
<template>
<form-select-wrap ref="wrap" :title="title"
                  :multiple="true"
                  :showPicker="showPicker"
                  @cancel="onClickArrow"
                  @confirm="() => this.$emit('confirm',this.activeList)">
  <template v-if="loading || empty" #content>
    <empty-loading :loading="loading" :empty="empty"/>
  </template>
  <template v-else #content>
    <div id="form-select-wrap-cascader">
      <div id="content-opt" class="root-container">
        <span v-for="(item,index) in showActiveList" :key='index' class="opt" :class="{ active: active===item }"
              @click="optClick(item, index)">
          {{ item.text || item.dictName }}
        </span>
      </div>
      <div class="bg-list"></div>
      <cell-list :list="list" :level="activeLevel" :cellActive="cellActive" @change="onCellChange">

      </cell-list>
    </div>
  </template>
</form-select-wrap>
</template>
<style lang="less">
#form-select-wrap-cascader {
  #content-opt {
    padding-top: 10px;

    > .opt {
      display: inline-block;
      background-color: #F5F5F5;
      padding: 3px 10px 4px;
      margin-right: 10px;
      margin-bottom: 10px;
      &.disabled{
        color: gray;
      }
      &.active {
        background-color: @yellow;
        color: @black;
      }
    }
  }

  .bg-list {
    background: #F5F5F5;
    height: 1.5rem;
  }
}
</style>
